import styled from 'styled-components/macro'
import { Box } from 'rebass/styled-components'

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  border-radius: 8px;
  padding: 1rem;
  padding: ${({ padding }) => padding};
  color: #fff !important;
  border-radius: 16px !important;
  background: rgba(11, 13, 14, 0.85) !important;
  border: 1px solid #2d3131;
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid #2d3131;
  background-color: #0b0d0e;
`

export const LightGreyCard = styled(Card)`
  border: 1px solid #2d3131);
  background-color: #0b0d0e;
`

export const GreyCard = styled(Card)`
  border: 1px solid #2d3131;
  background: #0b0d0e;
  z-index: 3;
`

export const DarkGreyCard = styled(Card)`
  color: #fff !important;
  border-radius: 16px !important;
`

export const DarkCard = styled(Card)`
  background-color: #0b0d0e;
`

export const OutlineCard = styled(Card)`
  border: 1px solid #2d3131 !important;
`

export const YellowCard = styled(Card)`
  background: rgba(11, 13, 14, 0.85) !important;
  border: 1px solid #2d3131;
  color: #fff;
  border-radius: 16px !important;
  box-shadow: none;
  font-weight: 500;
`

export const PinkCard = styled(Card)`
  background: rgba(11, 13, 14, 0.85) !important;
  border: 1px solid #2d3131;
  color: #fff;
  border-radius: 16px !important;
  font-weight: 500;
`

export const BlueCard = styled(Card)`
  background: rgba(11, 13, 14, 0.85) !important;
  border: 1px solid #2d3131;
  color: #fff;
  border-radius: 16px !important;
`
