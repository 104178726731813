import React, { useMemo } from 'react'
import { Text, TextProps } from 'rebass'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#FFF',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#fff' : '#EDEEF2',

    // backgrounds / greys
    bg01: 'black',
    bg0: darkMode ? '#2d3131' : '#2d3131',
    bg1: darkMode ? '#0b0d0e' : '#0b0d0e',
    bg2: darkMode ? '#2d3131' : '#2d3131',
    bg3: darkMode ? '#2d3131' : '#2d3131',
    bg4: darkMode ? '#565A69' : '#888D9B',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#1A2028' : '#6C7284',

    //specialty colors
    modalBG: darkMode ? '#0b0d0e' : '#0b0d0e',
    advancedBG: darkMode ? '#2d3131' : '#2d3131',

    //primary colors
    primary1: darkMode ? '#0b0d0e' : '#0b0d0e',
    primary2: darkMode ? '#2d3131' : '#2d3131',
    primary3: darkMode ? '#fff' : '#fff',
    primary4: darkMode ? '#15f2c9' : '#15f2c9',
    primary5: darkMode ? '#2d3131' : '#2d3131',
    primary6: darkMode ? '#2d3131' : '#2d3131',

    primary1_30: '#1a1f20',
    primary1_10: '#0E0F13',
    primaryTransparent: '#2d3131',

    // color text
    primaryText1: darkMode ? '#fff' : '#fff',
    primaryText2: darkMode ? '#fff' : '#fff',
    primaryText3: darkMode ? '#15f2c9' : '#15f2c9',

    // secondary colors
    secondary1: darkMode ? '#0b0d0e' : '#0b0d0e',
    secondary2: darkMode ? '#2d3131' : '#2d3131',
    secondary3: darkMode ? '#2d3131' : '#2d3131',

    secondary1_30: '#0b0d0e',
    secondary1_10: '#fff',

    dark0: 'rgba(16, 16, 18, 0.9)',
    dark1: 'rgba(12,26,35,0.9)',
    //dark2: 'rgba(10,14,36, 0.9)',
    dark2: '#2d3131',
    dark3: '#2d3131',
    dark4: '#0b0d0e',
    dark5: '#0b0d0e',
    darkTransparent: '#0b0d0e',
    darkTransparent2: '#0b0d0e',
    darkTransparent3: '#0b0d0e',

    bgGradient: `#0b0d0e`,

    // other
    red1: 'rgba(242,65,65,0.3)',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#e3a507',
    yellow2: '#ff8f00',
    yellow3: '#F3B71E',
    blue1: '#15f2c9',
    blue2: '#0b0d0e',

    error: '#FD4040',
    success: '#27AE60',
    warning: '#ff8f00',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#0b0d0e' : '#0b0d0e',
    // blue5: darkMode ? '#0b0d0e' : '#0b0d0e',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#0b0d0e' : '#0b0d0e',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'#fff'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'#fff'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'#15f2c9'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const ThemedBackground = styled.div<{ backgroundColor?: string | undefined }>`
  position: fixed;
  /* top: 0; */
  // top: 30vh;
  /* left: calc(-100vw / 2); */
  // right: 0;
  pointer-events: none;
  /* max-width: 100vw !important; */
  width: 100vw;
  /* width: 200vw; */
  height: 200vh;
  // mix-blend-mode: color;
  /* background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${
      backgroundColor ? backgroundColor : '#fc077d10'
    } 0%, rgba(255, 255, 255, 0) 100%)`}; */
  // background: transparent radial-gradient(closest-side at 50% 50%, #0b0d0e 0%, #20212400 100%) 0% 0% no-repeat padding-box;
  // opacity: 0.6;
  background-color: #0b0d0e;
  transform: translateY(-100vh);
  // will-change: background;
  // transition: background 450ms ease;
`

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}


 a {
   color: #15f2c9; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
  color: #fff;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: #15f2c9;
  font-feature-settings: 'ss01' on, 'ss02' on,  'cv01' on, 'cv03' on;
  
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: #fff;
  background-color: #0b0d0e;
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;

}
`
